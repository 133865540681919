import Vue from 'vue'
import Vuex from 'vuex'
import router from './module/router'
import listData from './module/listData'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    title: 'LIMS--首建科技',
    scrollPosition: { x: 0, y: 0 }
  },
  mutations: {
    setScrollPosition(state, position) {
      state.scrollPosition = position
    }
  },
  actions: {
    saveScrollPosition({ commit }) {
      commit('setScrollPosition', { x: window.pageXOffset, y: window.pageYOffset });
    },
    restoreScrollPosition({ state }) {
      window.scrollTo(state.scrollPosition.x, state.scrollPosition.y);
    }
  },
  modules: {
    router, listData
  }
})
export default store
