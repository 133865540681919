/*
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-07-19 11:07:12
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-16 15:30:08
 * @FilePath: \ding-lims\src\common\dd.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as dd from 'dingtalk-jsapi'
import { Notify } from 'vant'
export function getCode(callback) {
  const corpId = 'ding44d667a390ff02cdffe93478753d9884' // 企业id，可以到钉钉后台去查看
  if (dd.env.platform !== 'notInDingTalk') { // 判断是不在钉钉（pc、手机）内进行请求
      //使用SDK 获取免登授权码
      dd.runtime.permission.requestAuthCode({
        corpId: corpId,
        onSuccess: info => {
          // 根据钉钉提供的api 获得code后,再次调用这个callback方法
          // 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
          // callback 函数会等他执行完毕后在自己调用自己
          callback(info.code)
        },
        onFail: err => {
          alert(JSON.stringify(err))
        }
      })
  } else {
    Notify({ type: 'warning', message: '请使用钉钉' })
  }
}