<!--
 * @Author: zll zll1217168026@163.com
 * @Date: 2022-07-28 10:55:53
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2023-02-17 14:56:33
 * @FilePath: \ding-lims\src\App.vue
 * @Description:
-->
<template>
  <div id="app">
    <!-- <van-nav-bar safe-area-inset-top /> -->
    <!-- <router-view :key="this.$route.path"></router-view>      -->
     <keep-alive>
        <router-view v-if="this.$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive"></router-view>
    <!-- <div class="nav-bottom"></div> -->
    <van-tabbar class="su-tabbar" route active-color="#3d7cf3" inactive-color="#1a1a1a" fixed :placeholder="true">
      <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
      <van-tabbar-item v-for="(item, idx) in nav" :key="idx" icon-prefix="icon-font" :icon="item.menu_icon" :to="{ path: '/' + item.url }">{{item.title}}</van-tabbar-item>
    </van-tabbar>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Tabbar, TabbarItem, NavBar, NumberKeyboard} from 'vant'
import * as dd from 'dingtalk-jsapi'
import store from '@/store/index'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(NumberKeyboard)

export default {
  data() {
    return {
      active: 'Home',
      timestamp: new Date().getTime(),
      params: {
        uid: JSON.parse(localStorage.getItem('user'))?.user_id, // 当前登录用户ID
        title: '菜单', // 操作名称
        operate_ip: JSON.parse(localStorage.getItem('cip'))?.cip, // IP
        duration: 0, // 时长
        content: '浏览', // 具体操作内容
        type: '2' // 登陆type为1，打开菜单查询type类型为2，菜单内查询类型为3，导出为4；
      }
    }
  },
  watch: {
    '$route'(to) {
      // dd.ready(() => {
      //   dd.device.notification.alert({
      //     message: from.fullPath,
      //     title: "app form",
      //     buttonName: "收到"
      //   })
      // }) 
      // console.log(to)
      // const curl = localStorage.getItem('curl')
      // if (!to.path.includes('/login') && this.$common.isEmpty(curl)) {
      //   localStorage.setItem('curl', from.fullPath)
      // }
      // const user = this.$store.state.router.user
      if (dd.env.platform !== 'notInDingTalk') { // 判断是不在钉钉（pc、手机）内进行请求
        dd.ready(() => {
          dd.biz.navigation.setTitle({
            title: to.meta.title, // 控制标题文本，空字符串表示显示默认文本
          })
        })
      }
      // if (this.nav.length === 0 && !this.$common.isEmpty(user)) {
      //   this.getNav()
      // }
    },
    $route (to, from) {
      this.suRouter = from
      if (from.fullPath !== '/') {
        const times2 = new Date().getTime()
        this.params.duration = (times2 - this.timestamp) / 1000
        this.routerLog()
      }
    }
  },
  computed: {
    nav: () => {
      return store.getters['router/menuData'].slice(0, 2)
    }
  },  
  mounted() {
  // 通过监听hashchange事件 判断当前页面的路由与实际的路由是否一致
    window.addEventListener('hashchange', () => {
      let currPath = window.location.hash.slice(1)
      if (this.$route.path !== currPath) { // 不一致就跳转url的路由
        this.$router.push(currPath)
      }
    }, false)
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // document.onmouseup = function(event) {
    //   console.log(event)
    // }    
  },
  created: async function () {
    const user = this.$store.state.router.user
    if (this.$common.isEmpty(user)) {
      // this.toLogin()
      // await this.asyncUserLogin()
      // await this.asyncMenuData()
    } else {
      this.getNav()
    }
  },
  methods: {
    ...mapActions('router', ['asyncMenuData', 'asyncUserLogin']),
    getNav() {
      const nav = this.$store.state.router.menuData
      if (nav.length > 0) {
        this.nav = nav.slice(0, 2)
      } else {
        this.asyncMenuData().then(res => { 
          this.nav = res.slice(0, 2)
        })
      }
    },
    beforeunloadHandler (e) {
      e.preventDefault()
      e = e || window.event
      if (e) {
        const times2 = new Date().getTime()
        this.params.duration = (times2 - this.timestamp) / 1000
        this.params.content = '刷新[' + this.title + ']，路径：' + e.srcElement.URL
      }
      e.returnValue = '确定要离开吗?'
    },
    routerLog () {
      this.params.type = '2'
      this.params.content = '浏览[' + this.suRouter.meta.title + ']，路径：' + this.suRouter.fullPath
      // console.log(this.params)
      return
      // this.$post('log/add-log', 'api', this.params).then(res => {
      //   this.params.duration = 0
      //   this.timestamp = new Date().getTime()
      // })
    },
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => {
      this.beforeunloadHandler(e)
    })
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  max-width: 720px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f2f2f2;
}
h6,p{
  font-size: 14px;
}
h6{
  font-weight: 500;
  margin: 0;
  padding: 5px 0;
}
p{
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  line-height: 1.628em;
}
.red {
  color: #ff4b38;
}
.blue{
  color: #3d7cf3;
}
.green{
  color: #ff903f;
}
.gray-7{
  color: #4d4d4d;
}
.gray{
  color: #999999;
}
.tr{
  text-align: right;
  justify-content:flex-end;
  float: right;
}
.tc{
  text-align: center;
}
.fs12{
  font-size: 12px;
  font-weight: normal;
}
.nodisplay{
  display: none;
}
.plr16{
  padding-left: 16px;
  padding-right: 16px;
}
.mt6 {
  margin-top: 6px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.ml5{
  margin-left: 5px !important;
}
.van-button--mini{
  padding: 3px 10px !important;
}
.nav-bottom{
  display: block;
  clear: both;
  position: relative;
  padding-bottom: 80px
}
.su-tabbar ::v-deep .van-tabbar{
  z-index: 999;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}
</style>
