/*
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-06 11:05:38
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-07 16:10:13
 * @FilePath: \ding-lims\src\common\common.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 换算千分位，例：1,120,200.00
  toThousand: function (num) {
    var reg = /(\d)(?=(\d{3})+\b)/g
    return (num + '').replace(reg, '$1,')
  },
  // 时间戳格式化日期 返回 年-月-日 时:分:秒
  initDate (timeStamp) {
    if(timeStamp == 0) {
      return ''
    } else {
      var time = new Date(timeStamp * 1000);
      var y = time.getFullYear();
      var m = time.getMonth()+1;
      if(m < 10) {
        m = '0' + m
      }
      var d = time.getDate();
      if(d < 10) {
        d = '0' + d
      }
      var h = time.getHours();
      if(h < 10) {
        h = '0' + h
      }
      var mm = time.getMinutes();
      if(mm < 10) {
        mm = '0' + mm
      }
      var s = time.getSeconds();
      if(s < 10) {
        s = '0' + s
      }
      return y+'-'+m+'-'+d+' '+h+':'+mm+':'+s;
    }       
  },
  // 时间戳格式化日期 返回 年-月-日，-可自定义
  formatDate(timeStamp, Separator='-') {
    const year = timeStamp.getFullYear()
    const mon = (timeStamp.getMonth()+1) < 10 ? "0"+(timeStamp.getMonth()+1) : timeStamp.getMonth()+1
    const day = timeStamp.getDate()  < 10 ? "0"+(timeStamp.getDate()) : timeStamp.getDate()
    // const hour = timeStamp.getHours()  < 10 ? "0"+(timeStamp.getHours()) : timeStamp.getHours()
    // const min =  timeStamp.getMinutes()  < 10 ? "0"+(timeStamp.getMinutes()) : timeStamp.getMinutes()
    // const seon = timeStamp.getSeconds() < 10 ? "0"+(timeStamp.getSeconds()) : timeStamp.getSeconds()            
    const newDate = year + Separator + mon + Separator + day
    return newDate
  },
  // 判断对象是否为空
  isEmpty(obj) {
    if(typeof obj == "undefined" || obj == null || obj == ""){
      return true
    }else{
      return false
    }
  },
/*
 * @Description: 比较两个对象是否完全相同
 * @o1,对象1；o2，对象2；n，抛出对象后n位
 */
  isObjEqual(o1, o2, n) {
    const props1 = Object.getOwnPropertyNames(o1)
    const props2 = Object.getOwnPropertyNames(o2)
    if (props1.length != props2.length) {
      return false
    }
    for (let i = 0, max = props1.length - n; i < max; i++) {
      const propName = props1[i]
      if (o1[propName] !== o2[propName]) {
        return false
      }
    }
    return true
  },
  //判断当前设备是否为移动端
  isMobilePhone() {
    const ua = navigator.userAgent.toLowerCase()
    const t1 = /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(ua)
      // const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1
      return t1
  },
  // 获取当前滚动位置
  // getCurrentScrollPosition() {
  //   return {
  //     x: window.pageXOffset,
  //     y: window.pageYOffset
  //   }
  // },
  // 滚动到页面顶部
  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },
  // 滚动到页面底部
  // scrollToBottom() {
  //   const contentHeight = document.body.scrollHeight
  //   window.scrollTo({
  //     top: contentHeight,
  //     left: 0,
  //     behavior: 'smooth'
  //   })
  // },
  // 滚动到特定元素
  // scrollToElement(elementId) {
  //   const element = document.getElementById(elementId)
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }
}
