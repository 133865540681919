/*
 * @Author: zll zll1217168026@163.com
 * @Date: 2022-09-19 10:48:04
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-09-22 16:00:26
 * @FilePath: \ding-lims\src\common\directive.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import { get, post } from '@/api/https'
Vue.prototype.$get = get
Vue.prototype.$post = post
// 自定义埋点指令
Vue.directive("track", {  
  //钩子函数，只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置
  /*
   * el:指令所绑定的元素，可以用来直接操作 DOM
   * binding：一个对象，包含以下 property:
   *   name：指令名，不包括 v- 前缀。
   *   value：指令的绑定值，例如：v-my-directive="1 + 1" 中，绑定值为 2。
   *   expression：字符串形式的指令表达式。例如 v-my-directive="1 + 1" 中，表达式为 "1 + 1"。
   *   arg：传给指令的参数，可选。例如 v-my-directive:foo 中，参数为 "foo"。
   *   modifiers：一个包含修饰符的对象。例如：v-my-directive.foo.bar 中，修饰符对象为 { foo: true, bar: true }。
   * vnode：Vue 编译生成的虚拟节点
   */  
  bind : (el, binding, ) => {      
    if (binding.value) {
      //这里参数是根据自己业务可以自己定义    
      let parList = {
        uid: JSON.parse(localStorage.getItem('user'))?.user_id, // 当前登录用户ID
        operate_ip: JSON.parse(localStorage.getItem('cip'))?.cip, // IP
        type: '2', // 登陆type为1，打开菜单查询type类型为2，菜单内查询类型为3，导出为4；
        duration: new Date().getTime() - binding.value.duration, // 时长
        content: binding.value.behavior,
        currentUrl: binding.value.currentUrl,       
        title: binding.value.title,
        // preUrl: binding.value.preUrl,
        frontTriggerType: binding.value.triggerType,
        // businessCode: binding.value.businessCode,
        // service: "xxx",
      };
      //如果是浏览类型，直接保存
      if (binding.value.triggerType == "browse") {
        console.log("browse", params);
        //调用后台接口保存数据
        // api.eventTrack.saveEventTrack(params);
      } else if (binding.value.triggerType == "click") {
        //如果是click类型，监听click事件
        el.addEventListener("click",() => {
          var params = {
            uid: parList.uid,
            operate_ip: parList.operate_ip, // IP
            type: '2', // 登陆type为1，打开菜单查询type类型为2，菜单内查询类型为3，导出为4；
            duration: parList.duration, // 时长
            content: ''
          }        
          if(parList.title == '新增产品') {
            params.content = parList.title+'：'+parList.content.sampleName+'['+parList.content.erpCode+']'
          } else if(parList.title == '编辑产品') {
            params.content = parList.title+'：'+parList.content.sampleName+'['+parList.content.sampleCode+']'
          } else if(parList.title == '增删指标') {
            params.content = parList.content.sampleName+'['+parList.content.sampleCode+']修改产品指标'
          } else if(parList.title == '新增检测项') {
            params.content = parList.title+'：'+parList.content.itemName
          } else if(parList.title == '修改检测项') {
            params.content = parList.title+'：'+parList.content.itemName
          } else if(parList.title == '编辑设备') {
            params.content = parList.title+'：'+parList.content.equipmentName+'['+ parList.content.equipmentCode +']-' +parList.content.equipmentModel
          } else if(parList.title == '新增设备') {
            params.content = parList.title+'：'+parList.content.equipmentName+ '-' +parList.content.equipmentModel
          } else if(parList.title == '删除设备') {
            params.content = parList.title+'：'+parList.content
          } else if(parList.title == '同步供应商') {
            params.content = parList.content
          } else if(parList.title == '导出检测台账') {
            params.content = parList.content
          } else if(parList.title == '导出部门合格率') {
            params.content = parList.content
          } else if(parList.title == '导出产品合格率') {
            params.content = parList.content
          } else if(parList.title == '导出供应商合格率') {
            params.content = parList.content
          } else if(parList.title == '导出供应商产品到货合格率') {
            params.content = parList.content
          } else if(parList.title == '导出检测员分析') {
            params.content = parList.content
          } else if(parList.title == '导出设备分析') {
            params.content = parList.content
          } else if(parList.title == '提交报检'){ 
            console.log(parList.content.type)           
            if(parList.content.type == '') {
              params.content = '新建报检产品为：'+parList.content.form.sample_name+'['+parList.content.form.sample_code+']'
            } else if(parList.content.type == '1') {
              params.content = '复制新建报检产品为：'+parList.content.form.sample_name+'['+parList.content.form.sample_code+']'
            } else if(parList.content.type == '0') {
              params.content = '报检修改产品为：'+parList.content.form.sample_name+'['+parList.content.form.sample_code+']'
            }
          } else if(parList.title == '打印报检标签') {
            params.content = '打印标签：'+parList.content
          } else if(parList.title == '恢复') {
            params.content = '恢复：'+parList.content
          } else if(parList.title == '让步申请') { 
            var idx = parList.content.idx
            var list = parList.content.list 
            list.forEach((ele,index) => {
              if(index == idx) {
                params.content = '让步申请：'+ele.inspect_code
              }
            });            
          }else if(parList.title == '报检详情') {
            params.content = '查看报检详情：'+parList.content
          } else if(parList.title == '检测详情') {
            params.content = '查看检测详情：'+parList.content
          } else if(parList.title == '更换复检人') {
            var currentId = parList.content.currentId
            var sampleList = parList.content.sampleList    
            for (var i = 0; i < sampleList.length;i++) {
              if(sampleList[i].inspect_id == currentId) {
                params.content = '更换复检人：'+sampleList[i].inspect.inspect_code
              }
            }                          
          } else if(parList.title == '打印检测标签') {
            params.content = '打印标签：'+parList.content
          } else if(parList.title == '检测保存') {
            params.content = '保存：'+parList.content           
          } else if(parList.title == '检测提交') {  
            if(parList.content.check_status == '1') {
              params.content = '初检提交：'+parList.content.dataList.inspect.inspect_code
            } else if(parList.content.check_status == '2') {
              params.content = '复检提交：'+parList.content.dataList.inspect.inspect_code
            } else if(parList.content.check_status == '3') {
              params.content = '复检修改提交：'+parList.content.dataList.inspect.inspect_code
            } else if(parList.content.check_status == '4') {
              params.content = '让步提交：'+parList.content.dataList.inspect.inspect_code
            }                           
          }            
            post('log/add-log', 'api', params).then(res => {
            })
            //调用后台接口保存数据
            // api.eventTrack.saveEventTrack(params);
          },
          false
        )
      }
    }
  },
})
