/*
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-07-04 08:10:35
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-09-20 16:42:58
 * @FilePath: \ding-lims\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Notify } from 'vant'
import { get, post } from '@/api/https'
import common from '@/common/common.js'
import '@vant/touch-emulator'
import 'vant/lib/index.less'
import "vue-easytable/libs/theme-default/index.css"
import VueEasytable from "vue-easytable"
import Print from 'vue-print-nb'
import QRcode from './components/QRcode'
import './common/directive'

Vue.use(Print)
Vue.use(VueEasytable)
Vue.use(Notify)
Vue.use(QRcode)
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$common = common
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')