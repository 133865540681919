/*
 * @Author: zll zll1217168026@163.com
 * @Date: 2022-07-28 10:55:53
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-07 20:14:46
 * @FilePath: \ding-lims\src\store\module\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { get } from '@/api/https'
import { commonRoutes, routerMap, router404 } from '@/router/router'
import { getCode } from '@/common/dd'
// import { Notify } from 'vant'

const state = {
  routers: commonRoutes,
  menuData: [ ],
  user: '',
  hasGetRules: false, // 是否接口获取路由权限页面
  // componentRoutes: null // 存放组件按钮级别的权限
}
const getters = {
  user: state => state.user,
  hasGetRules: state => state.hasGetRules,
  menuData: state => state.menuData
}
const mutations = {
  concatRules(state, data) {
    state.routers = commonRoutes.concat(data, router404) // commonRoutes再数组得到后面，即把接口返回的权限页面进行筛选之后，与通同不需要权限的页面进行
    state.hasGetRules = true
  },
  setMenu (state, data) {
    state.menuData = data
  },
  setUser (state, data) {
    state.user = data
  }
}
const actions = {
  concatRoutes({ commit }, rules) {
    // const rules = res.data[0]._child
    return new Promise((resolve, reject) => {
      try {
        let arr = []
        if (rules.every(item => item[0])) { // Object.entries可以将对象转换为二维数组，如rules={name:1,age:2},Object.entries(rules)=[['name',1],['age',2]]
          arr = routerMap // every 遍历 数组中每个元素为true就返回true只要有一个元素不为true就返回false
        } else {
          arr = suFilter(routerMap, rules)
        }
        commit('concatRules', arr)
        resolve(state.routers)
      } catch (err) {
        reject(err)
      }
    })
  },
  asyncMenu ({ commit }) {
    return new Promise((resolve, reject) => {
      get('user/get-menu-list', 'api').then(res => {
        const data = res.data || []
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  asyncMenuData  ({ commit }) {
    return new Promise((resolve, reject) => {
      get('user/get-menu-list', 'api').then(res => {
        const data = res.data[0]._child || []
        const MenuData = data.filter(ref => {
          if (ref._child) {
            return ref.child = ref._child.filter(sub => {
              return sub.hide === '0'
            })
          } else {
            return ref.hide === '0'
          } 
        })
        commit('setMenu', MenuData)
        resolve(MenuData)
      }).catch(err => {
        reject(err)
      })
    })
  },
  asyncUser ({ commit }) {
    return new Promise((resolve, reject) => {
      get('user/get-user-info', 'api').then(res => {
        const data = res.data
        commit('setUser', data)
        localStorage.setItem('user', JSON.stringify(data))
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  asyncUserLogin({ commit }) {
    return new Promise((resolve, reject) => {
      getCode(code => {
        get('user/dd-auth-login', 'api', {
          code: code
        }).then(res => {
          const data = res.data
          localStorage.setItem('user', JSON.stringify(data))
          commit('setUser', data)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    }) 
  }
}
const suFilter = (routes, rules) => {
  const rulesArr = flatten(rules)
  // let rulesArr = []
  // rules.forEach(ele => {
  //   rulesArr = rulesArr.concat(ele._child)
  // })
  return routes.filter(item => {
    return rulesArr.some(ele => {
      if (ele.url && typeof (ele.url) !== 'undefined') {
        return ele.url === item.name
      } else {
        return false
      }
    })
  })
}
const flatten = (arr) =>{
  var res = [] // 存放一个临时数组
  arr.map(item => {
    //  如果是数组的话就继续循环，否则就push
    if (Array.isArray(item._child)) {
      res.push(item)
      res = res.concat(flatten(item._child))
    } else {
      res.push(item)
    }
  })
  return res
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
