/*
 * @Author: mofan huanmeng122@aliyun.com
 * @Date: 2022年7月27日 08:12:35
 * @LastEditors: mofan huanmeng122@aliyun.com
 * @LastEditTime: 2022年7月27日 08:12:35
 * @FilePath: \ding-lims\src\router\index.js
 * @Description: 临时数据存储
 */
const state = {
  isExcel: false,
  SampleType: [],
  ERPList: [],
  DTList: [],
  Subject: [],
  Params: {},
}
const getters = {
  getSampleType: (state) => {
    return state.SampleType
  }
}
const mutations = {
  setSampleType: (state, data) => {
    state.SampleType = data
  },
  setParams: (state, data) => {
    state.Params = data
  }
}
const actions = {
  asyncsetSampleType: ({ commit }, data) => {
    commit('setSampleType', data)
  },
  asyncSetParams: ({ commit }, data) => {
    commit('setParams', data)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
