/* eslint-disable no-console */
import axios from 'axios'
import qs from 'qs' // 一个序列化的插件，直接安装该插件即可（npm i qs）
// import store from '@/store/index' // vuex，引入全局的状态
import router from '@/router'
import store from '@/store/index'
import { Notify } from 'vant'

// import { Loading, Message } from 'element-ui' // 这里我是使用elementUI的组件来给提示
// const ExpiresTime = 86400000 // 设置登录过期时间（一天）
let baseURL = ''
// const loadingInstance = null // 加载全局的loading
// 创建一个axios实例
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api'
} else if (process.env.NODE_ENV === 'production') {
  baseURL = 'http://webapi.lims.cicg.tech/v1/'
}
const service = axios.create({
  // baseURL: baseURL,
  timeout: 60000,
  // `transformRequest`选项允许我们在请求发送到服务器之前对请求的数据做出一些改动，其作用是让参数（data）序列化
  // 该选项只适用于以下请求方式：`put/post/patch`
  // 数组里面的最后一个函数必须返回一个字符串、-一个`ArrayBuffer`或者`Stream`
  // qs.stringify()将对象 序列化成URL的形式，以&进行拼接（需要qs在此转化为formdata，和api开发人员规定）
  transformRequest: [
    function (data, headers) {
      delete headers['Content-Type']
      // return qs.stringify(data)
      return JSON.stringify(data)
    }
  ],
  // `paramsSerializer`是一个可选的函数，其作用是让参数（params）序列化
  // 该选项只适用于以下请求方式：`get / delete `
  paramsSerializer: function (params) {
    return qs.stringify(params)
  },
  // `transformResponse`选项允许我们在数据传送到`then/catch`方法之前对数据进行改动
  // 有些时候后台返回的response中真实数据在该对象下某个字段，例如 response.data，又或者是response本身，这里允许返回真实可利用的数据。
  transformResponse: [
    function (data) {
      return data
    }
  ],
  // 自定义loading参数，可在调用调用时候覆盖，也可以在拦截器里判断状态等。
  // 这样我们可以轻松的定义是否需要在请求之前使用loading组件。
  // loading: true,
  // 是否跨域，当然你也可以使用代理proxy 、nginx、
  // 但是一般公司都不会每次都设置代理，而是使用固定的开发模式，例如直接做一个跨域的设置，一劳永逸
  // 这里设置该属性还需要api开发者支持，设置后台的php程序里的header
  withCredentials: false,
  // 请求头，因为我们要使用formdata形式，所以设置如下
  // 'Content-Type': 'application/x-www-form-urlencoded'
  // 如果采用json传递给后台数据，格式如下
  headers: {
    // 'Content-Type': 'application/json; charset=utf-8',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'access-control-allow-origin': '*'
  }
})
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
// service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// const httpCode = { // 这里我简单列出一些常见的http状态码信息，可以自己去调整配置
//   400: '请求参数错误',
//   401: '权限不足, 请重新登录',
//   403: '服务器拒绝本次访问',
//   404: '请求资源未找到',
//   500: '内部服务器错误',
//   501: '服务器不支持该请求中使用的方法',
//   502: '网关错误',
//   504: '网关超时'
// }
// 请求前
service.interceptors.request.use(config => {
  // loadingInstance = Loading.service({ // 发起请求时加载全局loading，请求失败或有响应时会关闭
  //   spinner: 'fa fa-spinner fa-spin fa-3x fa-fw',
  //   text: '拼命加载中...'
  // })
  // 在这里：可以根据业务需求可以在发送请求之前做些什么:例如我这个是导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob，就可以在此处设置。
  // if (config.url.includes('pur/contract/export')) {
  //   config.headers['responseType'] = 'blob'
  // }
  // 我这里是文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
  // if (config.url.includes('pur/contract/upload')) {
  //   config.headers['Content-Type'] = 'multipart/form-data'
  // }
  // 利用了一个函数，每次请求设置一个半小时后的时间放在，store和本地缓存里
  // 再请求之前，如果该次请求距离上次请求超过半个小时，就清空store和本地缓存里的token
  // 这样做的功能是，如果半小时后没操作请求，自动退出，一种安全机制，后续会详细解说，此处忽略即可，不影响封装的实现。
  // this.$store.dispatch('CheckTokenExpiredTime')
  // 设置header里的token
  // console.log(store.getters['router/user'].access_token)
  const token = store.getters['router/user'].access_token
  // try {
  //   Authorization = JSON.parse(Authorization)
  // } catch (error) {
  //   // eslint-disable-next-line no-self-assign
  //   Authorization = Authorization
  // }
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
    // const NowTime = new Date().getTime()
    // if (NowTime - token.startTime > ExpiresTime) {
    //   // store.commit('router/Logout')
    //   // localStorage.setItem('token', '')
    //   router.push('/home')
    // } else {
    //   config.headers['token'] = 'Bearer ' + token
    // }
  } else {
    // router.push('/home?back=1')
  }
  // if (store.getters.token) {
  //   config.headers['Authorization'] = 'Bearer' + store.getters.token
  // }
  // config.url = baseURL + config.url
  return config
},
error => {
  Promise.reject(error)
})

// 请求后
service.interceptors.response.use(response => {
  // 服务器请求成功
  // 修改loading状态
  // loadingInstance.close()
  // 我们接口状态是在服务器请求200的情况下，再次返回自定义的状态码
  // 服务器请求成功，也有可能会告诉少个参数等报错，此处允许我们自定义哪些报错抛出，哪些需要重新登录等（401，403）。
  // else if (data.code === -2) {
  // const data = {
  //   code: 0,
  //   data: { data: Array(0), totalCount: 0 },
  //   message: '暂无数据'
  // }
  // return Promise.resolve(data)  }
  const data = JSON.parse(response.data)
  if (data.code === 0) {
    return Promise.resolve(data)
  } else if (data.code === -5) {
    router.push('/login')
    Notify(({ type: 'warning', message: '权限变更，请刷新页面重试' }))
    return Promise.reject(data)
  } else {
    // Notify(({ type: 'warning', message: data.message }))
    return Promise.reject(data)
  }
}, error => {
  // console.log(error)
  // loadingInstance.close()
  // 服务器请求错误
  // 为了保持统一的错误处理，修改了浏览器报错的格式，与接口返回的失败格式一致
  if (error.response) {
    // 根据请求失败的http状态码去给用户相应的提示
    // const tips = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
    // Message({
    //   message: tips,
    //   type: 'error'
    // })
    // console.log(tips)
    // if (error.response.status === 401) { // token或者登陆失效情况下跳转到登录页面，根据实际情况，在这里可以根据不同的响应错误结果，做对应的事。这里我以401判断为例
    //   router.push({
    //     path: '/login'
    //   })
    // }
    return Promise.reject(error)
  } else {
    // Message({
    //   message: '请求超时, 请刷新重试',
    //   type: 'error'
    // })
    return Promise.reject(new Error('请求超时, 请刷新重试'))
  }
})
/* 统一封装get请求 */
export const get = (url, base, params, config = {}) => {
  switch (base) {
    default:
      url = baseURL + url
  }
  return new Promise((resolve, reject) => {
    service({
      method: 'get',
      url,
      params,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
/* 统一封装post请求  */
export const post = (url, base, data, config = {}) => {
  switch (base) {
    default:
      url = baseURL + url
  }
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url,
      data,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
