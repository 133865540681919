/*
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-15 08:25:01
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-08-15 08:28:48
 * @FilePath: \ding-lims\src\components\QRcode\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import index from './index.vue'
// This is the point
const QRcode = {
  install: function(Vue){
    Vue.component('QRcode',index)
  }
}
// Export components
export default QRcode