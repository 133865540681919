/*
 * @Author: mofan huanmeng122@aliyun.com
 * @Date: 2022年7月27日 08:12:35
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-08 14:41:07
 * @FilePath: \ding-lims\src\router\index.js
 * @Description: 路由页面，所有路由地址从这里设置
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { commonRoutes } from './router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 定义一个函数来创建router
export const createRouter = routes => new VueRouter({
  mode: 'hash',
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   // savedPosition 是当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用。
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 } // 初始滚动位置
  //   }
  // },
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: commonRoutes
// })

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = createRouter(commonRoutes)
// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => { 
  // 获取localStorage里面的tokengit 
  const token = store.getters['router/user'].access_token
  if (to.path === '/login') {
    next()
    return false
  } else {
    if (token) {
      if (!store.state.router.hasGetRules) {
        // const rules = store.state.router.menuData
        store.dispatch('router/asyncMenu').then(rules => { // 调用接口 获取后端返回的路由权限页面/按钮/组件
          store.dispatch('router/concatRoutes', rules).then(routes => { // 合并页面
            resetRouter()
            routes.forEach(ele => router.addRoute(ele))
            // router.addRoutes(routes)
            // next({ ...to, replace: true }) // ...to避免routes还没有合并完整，replace:true路由跳转和替换之前路由
            const redirect = decodeURIComponent(to.path)
            if (to.path === redirect) {
              // hack方法 确保addRoute已完成 ,set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true }) // ...to避免routes还没有合并完整，replace:true路由跳转和替换之前路由
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
        }).catch(err => {
          console.log(err)
          next()
        })
      } else {
        next()
      }
    } else {
      next({
        path: '/login',
        name: 'Login',
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
})
export default router