/*
 * @Author: mofan huanmeng122@aliyun.com
 * @Date: 2022年7月27日 08:12:35
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2023-02-17 14:37:12
 * @FilePath: \ding-lims\src\router\index.js
 * @Description: 路由页面，所有路由地址从这里设置
 */
export const commonRoutes = [ // 不需要权限的页面
  {
    path: '/',
    redirect: '/home',
    component: () => import('../views/Home.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      Auth: false,
      frame: false,
      keepAlive: false,
      title: 'Loading'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      Auth: false,
      frame: false,
      keepAlive: false,
      title: 'LIMS-首建科技'
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: {
      Auth: false,
      frame: false,
      keepAlive: false,
      title: '打印报告'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/403.vue'),
    meta: {
      Auth: false,
      frame: true,
      keepAlive: false,
      title: '403'
    }
  }
]
export const routerMap = [ // 需要权限页面，需要根据后端接口返回的权限做筛选
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('../views/qrcode.vue'),
    meta: {
      Auth: false,
      frame: false,
      keepAlive: false,
      title: '扫码检测'
    }
  },
  {
    path: '/sample',
    name: 'Sample',
    component: () => import('../views/Sample.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '报检填报'
    }
  },
  {
    path: '/samplelist',
    name: 'SampleList',
    component: () => import('../views/SampleList.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: true,
      title: '报检列表'
    }
  },
  {
    path: '/sampledetail',
    name: 'SampleDetail',
    component: () => import('../views/SampleDetail.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '报检详情'
    }
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('../views/Analysis.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测填报'
    }
  },
  {
    path: '/analysislist',
    name: 'AnalysisList',
    component: () => import('../views/AnalysisList.vue'),
    meta: {
      Auth: true,
      frame: false,
      keepAlive: false,
      title: '检测列表'
    }
  },
  {
    path: '/analysisdetail',
    name: 'AnalysisDetail',
    component: () => import('../views/AnalysisDetail.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测详情'
    }
  },
  {
    path: '/analysistb',
    name: 'AnalysisTb',
    component: () => import('../views/AnalysisTb.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测填报'
    }
  },
  {
    path: '/analysisrb',
    name: 'AnalysisRb',
    component: () => import('../views/AnalysisRb.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '让步审核'
    }
  },
  {
    path: '/equipment',
    name: 'Equipment',
    component: () => import('../views/Equipment.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '设备维护'
    }
  },
  {
    path: '/testitem',
    name: 'TestItem',
    component: () => import('../views/TestItem.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测项维护'
    }
  },
  {
    path: '/productAdd',
    name: 'ProductAdd',
    component: () => import('../views/ProductAdd.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '产品添加'
    }
  },
  {
    path: '/productlist',
    name: 'ProductList',
    component: () => import('../views/ProductList.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '产品列表'
    }
  },
  {
    path: '/supplier',
    name: 'Supplier',
    component: () => import('../views/Supplier.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '供应商维护'
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/Statistics.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '数据统计'
    }
  },
  {
    path: '/userlist',
    name: 'UserList',
    component: () => import('../views/UserList.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '用户列表'
    }
  },
  {
    path: '/userrole',
    name: 'UserRole',
    component: () => import('../views/UserRole.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '用户角色'
    }
  },
  {
    path: '/systemLog',
    name: 'SystemLog',
    component: () => import('../views/SystemLog.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '系统日志'
    }
  },
  {
    path: '/dataLists',
    name: 'DataLists',
    component: () => import('../views/Statistics/DataLists.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测台账'
    }
  },
  {
    path: '/Datadetail',
    name: 'datadetail',
    component: () => import('../views/Statistics/Datadetail.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测台账详情'
    }
  },
  {
    path: '/DataDepartments',
    name: 'DataDepartments',
    component: () => import('../views/Statistics/DataDepartments.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '部门合格率'
    }
  },
  {
    path: '/DataProducts',
    name: 'DataProducts',
    component: () => import('../views/Statistics/DataProducts.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '产品合格率'
    }
  },
  {
    path: '/DataSuppliers',
    name: 'DataSuppliers',
    component: () => import('../views/Statistics/DataSuppliers.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '供应商合格率'
    }
  },
  {
    path: '/DataSupplierPro',
    name: 'DataSupplierPro',
    component: () => import('../views/Statistics/DataSupplierPro.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '供货合格率'
    }
  },
  {
    path: '/DataDetectionTask',
    name: 'DataDetectionTask',
    component: () => import('../views/Statistics/DataDetectionTask.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '任务分析'
    }
  },
  {
    path: '/DataInspector',
    name: 'DataInspector',
    component: () => import('../views/Statistics/DataInspector.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检验员分析'
    }
  },
  {
    path: '/DataEquipment',
    name: 'DataEquipment',
    component: () => import('../views/Statistics/DataEquipment.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '设备分析'
    }
  },
  {
    path: '/CheckList',
    name: 'CheckList',
    component: () => import('../views/CheckList.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: true,
      title: '化验列表'
    }
  },
  {
    path: '/PurchaseInspect',
    name: 'PurchaseInspect',
    component: () => import('../views/PurchaseInspect.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: true,
      title: '采购报检'
    }
  }, 
]

export const router404 = [ // 需要权限页面，需要根据后端接口返回的权限做筛选 packStocks  
  {
    path: '*',
    name: 'NoFound',
    component: () => import('../views/404.vue'),
    meta: {
      Auth: false,
      frame: true,
      keepAlive: false,
      title: '404'
    }
  },
  {
    path: '/Datadetail',
    name: 'datadetail',
    component: () => import('../views/Statistics/Datadetail.vue'),
    meta: {
      Auth: true,
      frame: true,
      keepAlive: false,
      title: '检测台账详情'
    }
  },
]
